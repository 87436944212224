import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CurrentUser } from '../auth/current-user';
import { Settings } from '../core/config/settings.service';
import { AppearanceListenerService } from '../shared/appearance/appearance-listener.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth/current-user";
import * as i2 from "../auth/auth.service";
import * as i3 from "@angular/router";
import * as i4 from "../core/config/settings.service";
import * as i5 from "../shared/appearance/appearance-listener.service";
var GuestGuard = /** @class */ (function () {
    function GuestGuard(currentUser, auth, router, settings, listener) {
        this.currentUser = currentUser;
        this.auth = auth;
        this.router = router;
        this.settings = settings;
        this.listener = listener;
    }
    GuestGuard.prototype.canActivate = function (route, state) {
        // allow to view guest pages in appearance editor
        if (this.listener.active)
            return true;
        // user is not logged in
        if (!this.currentUser.isLoggedIn())
            return true;
        this.router.navigate([this.auth.getRedirectUri()]);
        return false;
    };
    GuestGuard.ngInjectableDef = i0.defineInjectable({ factory: function GuestGuard_Factory() { return new GuestGuard(i0.inject(i1.CurrentUser), i0.inject(i2.AuthService), i0.inject(i3.Router), i0.inject(i4.Settings), i0.inject(i5.AppearanceListenerService)); }, token: GuestGuard, providedIn: "root" });
    return GuestGuard;
}());
export { GuestGuard };

import { HttpCacheClient } from '../http/http-cache-client';
import * as i0 from "@angular/core";
import * as i1 from "../http/http-cache-client";
var ValueLists = /** @class */ (function () {
    /**
     * ValueListsService Constructor.
     */
    function ValueLists(httpClient) {
        this.httpClient = httpClient;
    }
    /**
     * Get value list by name.
     */
    ValueLists.prototype.get = function (name) {
        return this.httpClient.getWithCache('value-lists/' + name);
    };
    /**
     * Fetch all existing app permissions.
     */
    ValueLists.prototype.getPermissions = function () {
        return this.httpClient.getWithCache('value-lists/permissions');
    };
    ValueLists.prototype.getValuesForSelects = function () {
        return this.httpClient.getWithCache('value-lists/selects');
    };
    ValueLists.ngInjectableDef = i0.defineInjectable({ factory: function ValueLists_Factory() { return new ValueLists(i0.inject(i1.HttpCacheClient)); }, token: ValueLists, providedIn: "root" });
    return ValueLists;
}());
export { ValueLists };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Settings } from '../core/config/settings.service';
import { AppHttpClient } from '../core/http/app-http-client.service';
import { Toast } from '../core/ui/toast.service';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LazyLoaderService } from '../core/utils/lazy-loader.service';
var RECAPTCHA_URL = 'https://www.google.com/recaptcha/api.js?render=';
var ContactComponent = /** @class */ (function () {
    function ContactComponent(settings, http, toast, router, lazyLoader) {
        this.settings = settings;
        this.http = http;
        this.toast = toast;
        this.router = router;
        this.lazyLoader = lazyLoader;
        this.loading = new BehaviorSubject(false);
        this.model = new FormGroup({
            name: new FormControl(),
            email: new FormControl(),
            message: new FormControl()
        });
        this.errors = {};
    }
    ContactComponent.prototype.ngOnInit = function () {
        this.loadRecaptcha();
    };
    ContactComponent.prototype.submitMessage = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loading.next(true);
                        return [4 /*yield*/, this.verifyRecaptcha()];
                    case 1:
                        if (!(_a.sent())) {
                            return [2 /*return*/, this.toast.open('Could not verify you are human.')];
                        }
                        this.http.post('contact-page', this.model.getRawValue())
                            .pipe(finalize(function () {
                            _this.loading.next(false);
                        })).subscribe(function () {
                            _this.errors = {};
                            _this.toast.open('Your message has been submitted.');
                            _this.router.navigate(['/']);
                        }, function (errorResponse) {
                            _this.errors = errorResponse.messages;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactComponent.prototype.loadRecaptcha = function () {
        this.lazyLoader.loadScript(RECAPTCHA_URL + this.settings.get('recaptcha.site_key'));
    };
    ContactComponent.prototype.verifyRecaptcha = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (!grecaptcha)
                return false;
            grecaptcha.ready(function () {
                grecaptcha.execute(_this.settings.get('recaptcha.site_key'), { action: 'contact_page' })
                    .then(function (token) {
                    _this.http.post('recaptcha/verify', { token: token })
                        .subscribe(function (response) { return resolve(response.success); });
                });
            });
        });
    };
    return ContactComponent;
}());
export { ContactComponent };

import { Settings } from "../config/settings.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/settings.service";
var LazyLoaderService = /** @class */ (function () {
    function LazyLoaderService(config) {
        this.config = config;
        this.loadedScripts = {};
    }
    /**
     * Load js script and return promise resolved on script load event.
     */
    LazyLoaderService.prototype.loadScript = function (url, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        //script is already loaded, return resolved promise
        if (this.loadedScripts[url] === 'loaded' && !params.force) {
            return new Promise(function (resolve) { return resolve(); });
            //script has never been loaded before, load it, return promise and resolve on script load event
        }
        else if (!this.loadedScripts[url]) {
            this.loadedScripts[url] = new Promise(function (resolve, reject) {
                var s = document.createElement('script');
                s.async = true;
                s.id = params.id || url.split('/').pop();
                s.src = url.indexOf('//') > -1 ? url : _this.config.getAssetUrl() + url;
                s.onload = function () {
                    _this.loadedScripts[url] = 'loaded';
                    resolve();
                };
                document.body.appendChild(s);
            });
            return this.loadedScripts[url];
            //script is still loading, return existing promise
        }
        else {
            return this.loadedScripts[url];
        }
    };
    LazyLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LazyLoaderService_Factory() { return new LazyLoaderService(i0.inject(i1.Settings)); }, token: LazyLoaderService, providedIn: "root" });
    return LazyLoaderService;
}());
export { LazyLoaderService };

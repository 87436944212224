/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading-indicator.component";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoadingIndicatorComponent, data: { "animation": [{ type: 7, name: "visibility", definitions: [{ type: 0, name: "true", styles: { type: 6, styles: { backgroundColor: "*", display: "block" }, offset: null }, options: undefined }, { type: 0, name: "false", styles: { type: 6, styles: { backgroundColor: "rgba(0,0,0,0)", display: "none" }, offset: null }, options: undefined }, { type: 1, expr: "true <=> false", animation: { type: 4, styles: null, timings: "225ms cubic-bezier(.4,0,.2,1)" }, options: null }], options: {} }] } });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "la-ball-spin-clockwise spinner"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, null); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-indicator", [], [[40, "@visibility", 0]], null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 49152, null, 0, i2.LoadingIndicatorComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isVisible; _ck(_v, 0, 0, currVal_0); }); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("loading-indicator", i2.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, { isVisible: "isVisible" }, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };

<ng-container *ngIf="currentUser.isLoggedIn()">
    <div class="menu-trigger" [matMenuTriggerFor]="menu">
        <img class="avatar" src="{{currentUser.get('avatar')}}">

        <div class="email">{{currentUser.get('email')}}</div>
        <mat-icon svgIcon="arrow-drop-down" class="dropdown-icon"></mat-icon>

        <button class="no-style mobile-toggle"><mat-icon svgIcon="menu"></mat-icon></button>
    </div>

    <mat-menu #menu="matMenu" [overlapTrigger]="false" class="logged-in-user-widget-menu">
        <a class="admin-link" mat-menu-item routerLink="/admin" *ngIf="currentUser.hasPermission('admin.access')">
            <mat-icon class="mat-icon" svgIcon="dashboard"></mat-icon>
            <span trans>Admin Area</span>
        </a>

        <ng-content></ng-content>

        <a class="account-settings-link" mat-menu-item routerLink="/account/settings">
            <mat-icon class="mat-icon" svgIcon="settings"></mat-icon>
            <span trans>Account Settings</span>
        </a>

        <a class="subscription-link" mat-menu-item routerLink="/billing/subscription" *ngIf="currentUser.isSubscribed()">
            <mat-icon class="mat-icon" svgIcon="credit-card"></mat-icon>
            <span trans>Subscription</span>
        </a>

        <div class="logout-item" mat-menu-item (click)="auth.logOut()">
            <mat-icon class="mat-icon" svgIcon="exit-to-app"></mat-icon>
            <span trans>Log Out</span>
        </div>
    </mat-menu>
</ng-container>
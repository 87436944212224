var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { UploadValidation } from './upload-validation';
import { prettyBytes } from '../../../core/utils/pretty-bytes';
var FileSizeValidation = /** @class */ (function (_super) {
    __extends(FileSizeValidation, _super);
    function FileSizeValidation(params, i18n) {
        var _this = _super.call(this) || this;
        _this.params = params;
        _this.i18n = i18n;
        _this.errorMessage = _this.i18n.t('Maximum file size is :number', { number: prettyBytes(_this.params.maxSize) });
        return _this;
    }
    FileSizeValidation.prototype.fails = function (file) {
        return this.params.maxSize < file.size;
    };
    return FileSizeValidation;
}(UploadValidation));
export { FileSizeValidation };
